import { useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import Footer from '@components/Footer/Footer';
import BaseLayout from '@components/layouts/BaseLayout';
import B2bAdminNavbar from '@components/Navbar/B2bAdminNavbar/B2bAdminNavbar';
import Navbar from '@components/Navbar/Navbar';
import AlternateLinks from '@components/utilities/AlternateLinks/AlternateLinks';
import {
  ContactsForDomainQuery,
  ContactsForDomainQueryVariables,
  FooterQuery,
  FooterQueryVariables,
} from 'graphql/generated/types/graphqlTypes';
import { FOOTER } from 'graphql/queries/footer';
import { CONTACTS_FOR_DOMAIN } from '../../graphql/queries/domainSpecificData';

interface Props {
  children?: ReactNode;
  b2bReportAccessHash?: string;
}

const WebPageLayout = ({ children, b2bReportAccessHash }: Props) => {
  const { i18n } = useTranslation();

  const { data } = useQuery<FooterQuery, FooterQueryVariables>(FOOTER, {
    variables: {
      locale: i18n.language,
    },
  });
  const { data: contactsForDomain } = useQuery<ContactsForDomainQuery, ContactsForDomainQueryVariables>(
    CONTACTS_FOR_DOMAIN,
  );

  return (
    <BaseLayout>
      <AlternateLinks />
      {b2bReportAccessHash ? <B2bAdminNavbar b2bReportAccessHash={b2bReportAccessHash} /> : <Navbar />}
      {children}
      {contactsForDomain?.contactsForDomain && <Footer contactsForDomain={contactsForDomain.contactsForDomain} />}
      <div dangerouslySetInnerHTML={{ __html: data?.lmcFooter?.htmlMarkup || '' }} />
    </BaseLayout>
  );
};

export default WebPageLayout;
